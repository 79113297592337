import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AuthComponent }				from './components/auth/auth.component';
import { ContentContainerComponent }	from './components/content-container/content-container.component';
import { CutlistBrowserComponent }		from './components/cutlist-browser/cutlist-browser.component';
import { HeaderComponent }				from './components/header/header.component';
import { JobsListComponent }			from './components/jobs-list/jobs-list.component';
import { PageNotFoundComponent }		from './components/page-not-found/page-not-found.component';
import { CWMaterialModule }				from		'./material.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule }		from './app-routing.module';
import { AppComponent }			from './components/app/app.component';

import { HttpModule } from '@angular/http';
import { DepartmentService } 			from './services/department.service';
import { JobService } from './services/factory_job.service';
import { LocalStorageService } from './services/localstorage.service';
import { UserService } from './services/user.service';
import { SessionService } from './session.service';
import { WarningDialog } from './components/warning-dialog/warning-dialog.component';

@NgModule({
	bootstrap: [AppComponent],
	declarations: [
		AppComponent,
		AuthComponent,
		ContentContainerComponent,
		CutlistBrowserComponent,
		JobsListComponent,
		PageNotFoundComponent,
		HeaderComponent,
		WarningDialog,
	],
	entryComponents:[
		CutlistBrowserComponent,
		WarningDialog,
	],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		CWMaterialModule,
		FormsModule,
		HttpModule,
		ReactiveFormsModule,
	],
	providers: [
		DepartmentService,
		JobService,
		LocalStorageService,
		SessionService,
		UserService,
	],
})
export class AppModule { }
