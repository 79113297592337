import { Attachment } from './Attachment';
import { FactoryWorkflow } from './FactoryWorkflow';

export class Job {
	public id					: number;
	public job_id					: number;
	public active				: boolean;
	public part_no				: number;
	public part_type			: string;
	public project_id			: number;
	public project_name			: string;
	public description			: string;
	public priority				: string;
	public billable_cost				: number;
	public unit_count			: number;
	public est_delivery_date	: string;
	public ups_tracking_number	: string;
	public designer				: string;
	public customer_service_rep	: string;
	public engineer				: string;
	public confirmed_date		: string;
	public engineered_date		: string;
	public exterior_material	: string;
	public texture				: string;
	public finish				: string;
	public finish_options		: string[];
	public sheen				: string;
	public door					: string;
	public shallow_drawer		: string;
	public drawer_box			: string;
	public interior				: string;
	public cabinet_type			: string;
	public attachments			: Attachment[];
	public factory_workflows	: FactoryWorkflow[];

	public constructor(){
		this.id						= 0;
		this.job_id					= 0;
		this.active					= true;
		this.part_no				= 0;
		this.part_type				= 'JobPart';
		this.project_name			= 'Project Name';
		this.description			= 'New Job Part';
		this.billable_cost				= 0.0;
		this.unit_count				= 0;
		this.est_delivery_date		= '10/12/2019';
		this.ups_tracking_number	= '';
		this.designer				= 'designer name';
		this.customer_service_rep	= 'cs name';
		this.engineer				= 'engineering name';
		this.confirmed_date			= '10/01/2019';
		this.engineered_date		= '10/03/2019';
		this.exterior_material		= 'Exterior Material';
		this.texture				= 'A Texture';
		this.finish					= 'A Finish';
		this.finish_options			= ['Finish Options'];
		this.sheen					= 'A Sheen';
		this.door					= 'A Door Frame';
		this.shallow_drawer			= 'A Shallow Drawer';
		this.drawer_box				= 'Drawer Box Details';
		this.interior				= 'Cabinet interior details';
		this.cabinet_type			= 'Cabinet Type';
		this.attachments			= [];
		this.factory_workflows		= [];
	}

	public getWorkflow(departmentId: number): FactoryWorkflow {
		let fw: FactoryWorkflow = this.factory_workflows.find(fw => fw.department == departmentId) || null;
		if (fw === null) {
			console.warn("Could not find workflow for departmentId: " + departmentId);
		}
		return fw;
	}
}
