import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-warning-dialog',
	templateUrl: './warning-dialog.component.html',
	styleUrls: ['./warning-dialog.component.css']
})
export class WarningDialog implements OnInit {
	public title: string = 'Warning';
	public message: string = null;
	public buttonText: string = 'OK';

	constructor(
		public dialogRef: MatDialogRef<WarningDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		data = data || {};
		if (data.title) this.title = data.title;
		if (data.message) this.message = data.message;
		if (data.buttonText) this.buttonText = data.buttonText;
	}

	ngOnInit() {
	}

}
