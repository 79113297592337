import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatTableDataSource, MatDialog, MatDialogConfig } from '@angular/material';
import { Department } from 'src/app/models/Department';
import { FactoryWorkflow } from 'src/app/models/FactoryWorkflow';
import { Job } from 'src/app/models/Job';
import { DepartmentService } from 'src/app/services/department.service';
import { JobService } from 'src/app/services/factory_job.service';
import { CutlistBrowserComponent } from '../cutlist-browser/cutlist-browser.component';
import { SessionService } from 'src/app/session.service';
import { forkJoin, Observable } from 'rxjs';
import { LoadJobsParams } from 'src/app/services/factory_job.service';
import { ALLOW_DEBUG, JOBS_REFRESH_TIMER_DURATION} from '../../config';
import { WarningDialog } from '../warning-dialog/warning-dialog.component';


@Component({
	selector: 'jobs-list',
	styleUrls: ['jobs-list.css'],
	templateUrl: 'jobs-list.html',
})
export class JobsListComponent implements OnInit, OnDestroy {
	public jobs: Job[] = [];
	public departments: Department[] = [];
	public loading: boolean = true;
	public dataSource: MatTableDataSource<Job> = new MatTableDataSource();
	public displayedColumns = [];

	public allowDebug = ALLOW_DEBUG;

	private refreshWarningTimer: any = null;
	private showWarningRefreshMessageWaitTime: number = JOBS_REFRESH_TIMER_DURATION;

	/** temp value for reading session value */
	public department: Department = null;

	/** temp value for reading session value */
	public filterViewType: string = null;

	public loadJobsParams: LoadJobsParams = {
		today: true
	};

	public firstColumns = [
		'priority',
		'part#',
		'project-name',
		'description',
		'ship-date',
		// 'cutlist',
		'price',
		'unit-count',
	];

	public checkColumns = [
		// 'ENGINEERING',
		'SAW/CNC',
		'EDGE BANDING',
		'DRAWERS',
		'DOORS',
		'SPECIALTY',
		'ASSEMBLY',
		'STAGING',
		'FINISH',
		'HANGING',
		'SHIPPING',
		'BILLING',
	];

	public lastColumns = [
		'designer',
		'customer-service',
		'engineer',
		'confirmed-date',
		'engineered-date',
		// 'actual-date-shipped',
		'exterior-material',
		'texture',
		'finish',
		'finish-options',
		'sheen',
		'door-frame',
		'shallow-drawer',
		'drawer-box',
		'cabinet-interior',
		'cabinet-type',
	];


	constructor(
		public departmentService: DepartmentService,
		public dialog: MatDialog,
		public jobService: JobService,
		public sessionService: SessionService
	) { }

	ngOnInit(): void {
		forkJoin(
			this.loadJobs(),
			this.departmentService.loadDepartments()
		).subscribe(([jobs, departments]) => {
			this.jobs = this.sortJobs(jobs);
			this.departments = departments;
			this.resolveColumns();
			this.sessionService.selectedDepartment.subscribe(this.onDepartmentChange.bind(this));
			this.sessionService.selectedFilterViewType.subscribe(this.onFilterViewTypeChange.bind(this));
			this.loading = false;
		});
		this.sessionService.setRefreshAction(this.refresh.bind(this));
		this.setWarningRefreshTimer();
	}

	private sortJobs(jobs:Job[]): Job[] {
		let priorityToInt = (priority:string) => {
			let p = 0;
			if (priority == 'normal') p = 1;
			if (priority == 'rush')   p = 2;
			return p;
		};

		let sorted_by_priority = jobs.sort((a, b) => {
			let _a = priorityToInt(a.priority);
			let _b = priorityToInt(b.priority);
			return _b - _a;
		});

		// we want the higher priority values to stay bubbled up to the top, we need to strip off the time parts of these dates.
		let sorted_by_date = sorted_by_priority.sort((a, b) => {
			// will sort dates in ascending order
			let _a = a.est_delivery_date;
			let _b = b.est_delivery_date;

			// handle nulls
			if (!_a && !_b) return 0;
			if (!_a && _b) return -1;
			if (_a && !_b) return 1;

			// not using string padStart because it isn't supported on IE11
			// all of this is to pad the month and day so we can more accurately see which is first
			let a_values = { date: new Date(_a) };
			let b_values = { date: new Date(_b) };
			for (let x of [a_values, b_values]) {
				let year = x.date.getFullYear().toString();
				let month = (x.date.getMonth() + 1).toString();
				let day = x.date.getDate().toString();
				if (month.length == 1) {
					month = '0' + month;
				}
				if (day.length == 1) {
					day = '0' + day;
				}
				x['date_only'] = year + month + day;
			}
			if (a_values['date_only'] < b_values['date_only']) return -1;
			if (a_values['date_only'] > b_values['date_only']) return 1;
			return 0;
		});
		return sorted_by_date;
	}

	private setWarningRefreshTimer(): void {
		if (this.setWarningRefreshTimer != null) {
			try { clearInterval(this.refreshWarningTimer); }
			catch (e) { console.warn("Could not clear the refresh warning timer.") }
		}
		this.refreshWarningTimer = setTimeout(this.showWarningRefreshMessage.bind(this), this.showWarningRefreshMessageWaitTime);
	}

	private showWarningRefreshMessage(): void {
		console.log("showWarningRefreshMessage()");
		let dialogRef = this.dialog.open(WarningDialog, {
			data: {
				title: 'Warning',
				message: 'It has been a while since your last refresh, please refresh the list to make sure you have the latest info!',
				buttonText: 'OK'
			}
		});
		dialogRef.afterClosed().subscribe( result => {
			this.refresh(this.setWarningRefreshTimer.bind(this));
		});
	}

	private refresh(onComplete: () => void): void {
		this.setWarningRefreshTimer();
		this.loadJobs().subscribe((jobs:Job[]) => {
			this.jobs = this.sortJobs(jobs);
			this.rebuild();
			onComplete();
		});
	}

	private onDepartmentChange(d:Department): void {
		// No department set in session
		if (!d) return;
		this.department = d;
		this.rebuild();
	}

	private onFilterViewTypeChange(s:string): void {
		this.filterViewType = s;
		this.rebuild();
	}

	public loadJobs(): Observable<Job[]> {
		return this.jobService.loadJobs(this.loadJobsParams);
	}

	private rebuild(): void {
		let partType: string = null;
		switch (this.filterViewType) {
			case 'JOBS':            partType = 'JobPart'; break;
			case 'SERVICE/SAMPLES': partType = 'S/ST';    break;
			default:                partType = 'JobPart'; break;
		}
		// implement active
		let filteredJobs = (this.jobs || []).filter((job) => job.part_type == partType) || [];

		// No department set
		if (!this.department) {
			return;
		}

		if (this.department.slug !== 'admin') {
			filteredJobs = filteredJobs.filter(job => {
				// return if the workflow for the selected department is "today" worthy
				let wf = job.factory_workflows.find(w => w.department == this.department.id);
				if (wf.complete == null) return true;

				const today = new Date()
				let completed_as_int = Date.parse(wf.complete);
				let c = new Date(completed_as_int);
				let wf_is_today = (
					c.getDate()  == today.getDate()  &&
					c.getMonth() == today.getMonth() &&
					c.getDay()   == today.getDay()
				)
				return wf_is_today;
			});
		}

		this.dataSource.data = filteredJobs;
		this.resolveColumns();
	}

	private resolveColumns() {
		let d = this.department;

		// No department set in session, cannot resolveColumns()
		if (!d) return;

		if (this.sessionService.selectedFilterViewType.getValue() == 'SERVICE/SAMPLES' && this.firstColumns.indexOf('ups-tracking-number') == -1) {
			this.firstColumns.splice(5, 0, 'ups-tracking-number');
		} else if (this.sessionService.selectedFilterViewType.getValue() == 'JOBS' && this.firstColumns.indexOf('ups-tracking-number') > -1) {
			this.firstColumns = this.firstColumns.filter(function(e) {
				return e !== 'ups-tracking-number';
			});
		}

		this.displayedColumns = this.firstColumns.concat(
			(d.name == 'ADMIN')
			? this.checkColumns
			: [d.name.toUpperCase()], this.lastColumns);
			// console.log('DisplayedColumns', this.displayedColumns);
	}

	public getWorkflow(job: Job, slug: string): FactoryWorkflow {
		let department:Department = this.department.name == "ADMIN"
			? (this.departments.find(d => d.slug == slug) || null)
			: this.department;
		let fw: FactoryWorkflow = job.getWorkflow(department.id);
		if (fw === null) {
			console.warn("Could not find workflow for slug: " + slug);
		}
		return fw;
	}

	public toggleWorkflow(workflow:FactoryWorkflow): void {
		workflow.complete = workflow.complete === null
			? new Date().toISOString()
			: null;
		this.jobService.patchWorkflow(workflow).subscribe((result) => {
			console.log(result);
		});
	}

	updateJob(job:Job) {
		this.jobService.patchJob(job).subscribe((result) => {
			console.log(result);
		});
	}

	openCutlistBrowser(job: Job) {
		const dialogRef = this.dialog.open(CutlistBrowserComponent, {
			data: {
				job
			},
			panelClass: 'large-dialog-panel'
		} as MatDialogConfig);
	}

	public ngOnDestroy(): void {
		this.sessionService.setRefreshAction(null);
	}

	public debug(): void {
		// helpful things to look at
		// this.sessionService.selectedDepartment.getValue()
		// this.sessionService.selectedFilterViewType.getValue()
		debugger;
	}
}
