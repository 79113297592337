import { Component, } from "@angular/core";
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'auth',
	styleUrls: ['auth.css'],
	templateUrl: 'auth.html',
})
export class AuthComponent {
	public formGroup;
	public formErrors = false;
	public loginFailed = false;
	public showProgressBar = false;
	private user: User;

	constructor(
		private router				: Router,
		private userService			: UserService,
		public	snackBar			: MatSnackBar,
		public	fb					: FormBuilder,
	) {}

	public loginForm = this.fb.group({
		password: ["", Validators.required],
		username: ["", Validators.required],
	});

	public validateUser() {
		let form		= this.loginForm.value;
		let username	= form.username;
		let password	= form.password;
		if ( !(username && password) ) {
			this.formErrors = true;
			return;
		}

		this.loginFailed		= false;
		this.formErrors			= false;
		this.showProgressBar	= true;
		this.login(username, password);
	}

	public login(username:string, password:string): void {
		this.loginFailed = false;
		this.userService.login(username, password).subscribe(
			(userResponse:User) => {
				this.handleLoginSuccess(userResponse);
			},
			error => {
				console.log(error);
				this.loginFailed = true;
				this.showProgressBar = false;
			}
		);
	}

	private handleLoginSuccess(response) {
		this.showProgressBar = false;
		this.user = response;
		if (this.user.groups.factory_floor) {
			this.router.navigate(['workflows']);
		} else {
			this.openSnackBar("You are not authorized.");
		}
	}

	openSnackBar(message: string, action: string = ''): void {
		this.snackBar.open(message, action, {
			// make the snackbar stay open longer for longer messages
			duration: message.length * 70,
		});
	}
}
