import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { User } from './models/User';
import { LocalStorageService } from './services/localstorage.service';
import { Router } from '@angular/router';
import { Department } from './models/Department';

export type RefreshCallback = (callback: Function) => any;

@Injectable()
export class SessionService {
	public user: User = null;
	public selectedDepartment = new BehaviorSubject<Department>(null);
	public selectedFilterViewType = new BehaviorSubject<string>(null);
	private refreshAction: RefreshCallback = null;

	constructor(
		private localStorageService: LocalStorageService,
		private router: Router
	){}

	public setRefreshAction(action: RefreshCallback): void {
		this.refreshAction = action;
	}

	public runRefreshAction(onDone: RefreshCallback): void {
		try { this.refreshAction(onDone); }
		catch (e) { console.warn(e); }
	}

	public logout(): void {
		this.user = null;
		this.localStorageService.clearApiToken();
		this.router.navigate(['']);
	}
}
