import { Injectable } from "@angular/core";
import { Http } from '@angular/http';
import { map, } from 'rxjs/operators';
import { CWHttpResponse } from '../interfaces/cw-http-response.interface';
import { Department } from '../models/Department';
import { SessionService } from '../session.service';
import { HttpService } from './http.service';
import { LocalStorageService } from './localstorage.service';

@Injectable()
export class DepartmentService extends HttpService {

	constructor(
		protected http: Http,
		protected localStorageService: LocalStorageService,
		protected sessionService: SessionService,
	) {
		super(http, localStorageService, sessionService);
	}

	public loadDepartments() {
		return this._get(['web','departments'])
			.pipe(
				map((response: CWHttpResponse) => {
					let departments: any[] = response.body;
					departments = ( departments || []).map(d => <Department> Object.setPrototypeOf(d, Department.prototype));
					return departments;
				}),
			);
	}
}
