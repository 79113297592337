import { Component, OnInit } from "@angular/core";
import { Department } from 'src/app/models/Department';
import { API_ROOT, HIDE_STAGING_INDICATOR } from '../../config';
import { DepartmentService } from "../../services/department.service";
import { JobService } from 'src/app/services/factory_job.service';
import { SessionService } from 'src/app/session.service';

@Component({
	selector: 'header',
	styleUrls: ['header.css'],
	templateUrl: 'header.html',
})
export class HeaderComponent implements OnInit {

	public refreshing = false;

	public filterViewTypes: string[] = ['SERVICE/SAMPLES', 'JOBS'];
	public departments: Department[] = [];

	/** temp value for setting session value */
	public filterViewType: string = null;
	/** temp value for setting session value */
	public department: Department = null;

	constructor(
		public departmentService: DepartmentService,
		public jobService: JobService,
		public sessionService: SessionService
	) {
		this.filterViewType = this.filterViewTypes[0];
		this.setViewType();
	}

	public ngOnInit(): void {
		this.departmentService.loadDepartments().subscribe((departments:Department[]) => {
			this.departments = departments;
			this.sessionService.selectedDepartment.subscribe((d:Department) => {
				this.department = d;
			});
			// auto select first
			if ((departments || <any>{}).length) {
				this.sessionService.selectedDepartment.next(this.departments[0]);
			}
		});

		this.sessionService.selectedFilterViewType.subscribe((s:string) => {
			console.log("filterViewType changed in session");
			this.filterViewType = s;
		});
	}

	public setViewType(): void {
		this.sessionService.selectedFilterViewType.next(this.filterViewType);
	}
	public setDepartment() {
		this.sessionService.selectedDepartment.next(this.department);
	}

	public showStagingIndicator(): boolean {
		let apiIsProduction = (API_ROOT || "").indexOf('//api.cottonwoodcabinets.com') == -1;
		return apiIsProduction && !HIDE_STAGING_INDICATOR;
	}

	public logout(): void {
		this.sessionService.logout();
	}

	public refresh(): void {
		this.refreshing = true;
		this.sessionService.runRefreshAction(this.onRefreshCompleted.bind(this));
	}

	public onRefreshCompleted(): void {
		this.refreshing = false;
	}
}
