import { Injectable } from "@angular/core";
import { Headers, Http } from '@angular/http';
import { Observable, of } from 'rxjs';
import { concatMap, map } from "rxjs/operators";
import { PathParts } from '../interfaces/path-parts.interface';
import { User } from '../models/User';
import { SessionService } from '../session.service';
import { HttpService } from './http.service';
import { LocalStorageService } from './localstorage.service';

@Injectable()
export class UserService extends HttpService {
	constructor(
		protected http: Http,
		protected localStorageService: LocalStorageService,
		protected sessionService: SessionService,
	){
		super(http, localStorageService, sessionService);
	}

	login(username:string, password:string): Observable<object> {
		let body = {
			password,
			username,
		};
		// bypass the HTTP wrapper since it uses our API key
		let path = this.getFullPath(<PathParts>{uri: ['rest-auth', 'login']});
		let headers = new Headers();
		return this.http.post(path, body, {headers})
			.pipe(
				concatMap(response => {
					response = response.json();
					this.localStorageService.setApiToken(response['key']);
					return this.tokenLogin();
				})
			);
	}

	public tokenLogin(): Observable<User> {
		let token = this.localStorageService.getApiToken();
		return token ? this.fetchUserInfoFromToken() : of(null);
	}

	private fetchUserInfoFromToken(): Observable<User> {
		let user = null;
		return this._get(['rest-auth', 'user'])
			.pipe(
				map(response => {
					user = <User>Object.setPrototypeOf(response.body, User.prototype);
					this.sessionService.user = user;
					// false to not force our defaults
					//user.setDefaultSettings(false);
					return user;
				})
			);
	}
}
