import {NgModule} from '@angular/core';
import {
	MatCardModule,
	MatCheckboxModule,
	MatDialogModule,
	MatFormFieldModule,
	MatOptionModule,
	MatPaginatorModule,
	MatProgressBarModule,
	MatSelectModule,
	MatSnackBarModule,
	MatTableModule,
	MatButtonModule,
	MatProgressSpinnerModule,
} from '@angular/material';


@NgModule({
	exports: [
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatFormFieldModule,
		MatOptionModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatSelectModule,
		MatSnackBarModule,
		MatTableModule,
		MatButtonModule,
		MatProgressSpinnerModule,
	]
})
export class CWMaterialModule { }
