import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[fps-app]',
  styleUrls: ['app.css'],
  templateUrl: 'app.html',
})
export class AppComponent implements OnInit {
  constructor() {}

  ngOnInit(): void { }
}
