export class Attachment {
	public id	: number;
	file__url	: string;
	file__id	: number;
	uuid		: string;
	description	: string;

	public constructor(){
		this.id				= 0;
		this.file__url		= '';
		this.file__id		= 0;
		this.uuid			= '';
		this.description	= '';
	}
}
