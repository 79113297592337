import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Job } from 'src/app/models/Job';

export interface DialogData {
	job: Job;
}

@Component({
	selector: 'cutlist-browser',
	styleUrls: ['cutlist-browser.css'],
	templateUrl: 'cutlist-browser.html',
})
export class CutlistBrowserComponent {

	job: Job;
	image_url: string;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DialogData
	) {
		this.job = data.job;
		this.image_url = this.job.attachments[0].file__url;
	}
}
