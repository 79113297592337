import { NgModule }				from '@angular/core';
import { RouterModule, Routes }	from '@angular/router';

import {JobsListComponent}		from './components/jobs-list/jobs-list.component';
import {PageNotFoundComponent}	from './components/page-not-found/page-not-found.component';

// other
import {AuthComponent}			from './components/auth/auth.component';
// import {AuthGuard}			from './AuthGuard';

const routes: Routes = [
	{
		component: AuthComponent,
		path: '',
	},
	{
		// canActivate: [AuthGuard],
		component: JobsListComponent,
		path: 'workflows',
	},
	//{
	//	// canActivate: [AuthGuard],
	//	component: JobsListComponent,
	//	path: 'inactive',
	//},
	// redirect all other routes
	{
		component: PageNotFoundComponent,
		path: '**',
	},
];

@NgModule({
	exports: [RouterModule],
	// providers: [
	// 	JobService,
	// ],
	imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule { }
