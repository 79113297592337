import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { CWHttpResponse } from '../interfaces/cw-http-response.interface';
import { Attachment } from '../models/Attachment';
import { FactoryWorkflow } from '../models/FactoryWorkflow';
import { Job } from '../models/Job';
import { SessionService } from '../session.service';
import { HttpService } from './http.service';
import { LocalStorageService } from './localstorage.service';

export interface LoadJobsParams {
	today: boolean
}

@Injectable()
export class JobService extends HttpService {
	constructor(
		protected http: Http,
		protected localStorageService: LocalStorageService,
		protected sessionService: SessionService,
	){
		super(http, localStorageService, sessionService);
	}

	public getJob(id:number): Observable<Job> {
		return this._get(['factory_jobs', id])
			.pipe(
				map((rsp:CWHttpResponse) => {
					let job = rsp.body.job;
					return Object.setPrototypeOf(job, Job.prototype);
				}),
			);
	}

	public loadJobs(params:LoadJobsParams) {
		return this._get(['web', 'factory_jobs'], params)
			.pipe(
				map((response: CWHttpResponse) => {
					let jobs: Job[] = [];
					jobs = (response.body || []).map(j => <Job> Object.setPrototypeOf(j, Job.prototype));
					for (let job of jobs) {
						if (job.factory_workflows === undefined) {
							console.warn("`<Job " + job.id + ">.factory_workflows` is undefined, set to []");
							job.factory_workflows = [];
						}
						if (job.factory_workflows.length) {
							job.factory_workflows = job.factory_workflows
								.map(fw => <FactoryWorkflow> Object.setPrototypeOf(fw, FactoryWorkflow.prototype));
						}

						if (job.attachments === undefined) {
							console.warn("`<Job " + job.id + ">.attachments` is undefined, set to []");
							job.attachments = [];
						}
						if (job.attachments.length) {
							job.attachments = job.attachments
								.map(a => <Attachment> Object.setPrototypeOf(a, Attachment.prototype));
						}
					}
					return jobs;
				})
			);
	}

	public patchJob(job: Job) {
		return this._patch(['web', 'jobs', job.job_id], {
			'ups_tracking_number' : job.ups_tracking_number
		});
	}

	public patchWorkflow(workflow:FactoryWorkflow): Observable<FactoryWorkflow> {
		return this._patch(['web', 'factory_workflows', workflow.id], workflow).pipe(
			map((response: CWHttpResponse) => {
				return Object.setPrototypeOf(response.body, FactoryWorkflow.prototype);
			})
		);
	}


}
