import { Headers, Http, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { API_ROOT } from "../config";
import { CWHttpResponse } from  "../interfaces/cw-http-response.interface";
import { PathParts } from "../interfaces/path-parts.interface";
import { SessionService } from "../session.service";
import { LocalStorageService } from "./localstorage.service";

let R = /(?:\/|\.\w+)(?:\?.*)?$/;
let _check_url = (url:string) => {
	if ( !R.test(url)) {
		console.warn(`API url without a trailing slash: ${url}`);
	}

	return url;
};

export class HttpService {
	protected useApiVersion = false;
	private apiVersion = 'v1';

	/** Will be set back to null after every successful request */
	protected customErrorHandler: Function = null;
	protected customResponseHandler: Function = null;

	constructor(
		/** use http raw to bypass token check */
		protected http: Http,
		protected localStorageService: LocalStorageService,
		protected sessionService: SessionService,
	){ }

	protected apiTokenProtect(): void {
		if (!this.localStorageService.getApiToken()){
			this.sessionService.logout();
			throw new Error("No token, logging out");
		}
	}

	/**
	 * Wrapper for Angular's http.get().
	 * Attaches headers, builds our api-url, and other conveniences that all of our requests need.
	 * @param uri          will be used to build the end point path.
	 * @param urlParams    will be turned into "?key=value&key=value" at the end of the uri.
	 * @param directAppend Will directly append to the end of the URL, good for Q[]= or any other dynamic rest.
	 *                     Don't put a ? in the string.
	 */
	protected _get(uri:any[], urlParams:object=null, options:RequestOptions=new RequestOptions(), directAppend:string=''): Observable<CWHttpResponse> {
		this.apiTokenProtect();
		if (this.useApiVersion) {
			uri = [this.apiVersion, ...uri];
		}
		let path = _check_url(this.getFullPath({ uri, params: urlParams }));
		path = this.appendDirectAppend(path, directAppend);
		if (options == null) {
			options = new RequestOptions();
		}
		options.headers = this.headers;
		return this.http.get(path, options)
			.pipe(
				map(response => {
					if (this.customResponseHandler) {
						let handler = this.customResponseHandler;
						this.customResponseHandler = null;
						return handler(response);
					}
					this.customErrorHandler = null;
					return this.parseResponse(response);
				}),
				catchError(error => {
					let handler = this.customErrorHandler ? this.customErrorHandler(error) : this.handleError(error);
					this.customErrorHandler = null;
					return handler;
				}
			)
		);
	}

	/**
	 * Wrapper for Angular's http.post().
	 * Attaches headers, builds our api-url, and other conveniences that all of our requests need.
	 * @param uri       used to build the end point path.
	 * @param body      the payload, can be a <new FormData()> to mimic an html form submit.
	 * @param urlParams turned into "?key=value&key=value" at the end of the uri.
	 * @param options   Other options you might want to pass in, headers will be automatically added
	 */
	protected _post(uri: any[], body: any, urlParams: object = null, options:RequestOptions=new RequestOptions()): Observable<CWHttpResponse> {
		this.apiTokenProtect();
		if (this.useApiVersion) {
			uri = [this.apiVersion, ...uri];
		}
		let path = _check_url(this.getFullPath({ uri, params: urlParams }));
		if (options == null) {
			options = new RequestOptions();
		}
		options.headers = this.headers;
		return this.http.post(path, body, options )
			.pipe(
				map(response => {
					if (this.customResponseHandler) {
						let handler = this.customResponseHandler;
						this.customResponseHandler = null;
						return handler(response);
					}
					this.customErrorHandler = null;
					return this.parseResponse(response);
				}),
				catchError(error => {
					let handler = this.customErrorHandler ? this.customErrorHandler(error) : this.handleError(error);
					this.customErrorHandler = null;
					return handler;
				})
			);
	}

	/**
	 * Wrapper for Angular's http.put().
	 * Attches headers, builds our api-url, and other conveniences that all of our requests need.
	 * @param uri
	 * @param body
	 * @param urlParams
	 * @param options
	 * @param directAppend
	 */
	protected _put(uri:any[], body:any, options:RequestOptions=new RequestOptions()): Observable<CWHttpResponse> {
		this.apiTokenProtect();
		if (this.useApiVersion) {
			uri = [this.apiVersion, ...uri];
		}
		let path = _check_url(this.getFullPath({uri}));
		if (options == null) {
			options = new RequestOptions();
		}
		options.headers = this.headers;
		return this.http.put(path, body, options)
			.pipe(
					map(response => {
					if (this.customResponseHandler){
						let handler = this.customResponseHandler;
						this.customResponseHandler = null;
						return handler(response);
					}
				}),
				catchError(error => {
					let handler = this.customErrorHandler ? this.customErrorHandler(error) : this.handleError(error);
					this.customErrorHandler = null;
					return handler;
				})
			);
	}

	/**
	 * Wrapper for Angular's http.patch().
	 * Attaches headers, builds our api-url, and other conveniences that all of our requests need.
	 * @param uri       used to build the end point path.
	 * @param body      the payload, can be a <new FormData()> to mimic an html form submit.
	 * @param urlParams turned into "?key=value&key=value" at the end of the uri.
	 * @param directAppend Will directly append to the end of the URL, good for Q[]= or any other dynamic rest. Don't put a ? in the string.
	 */
	protected _patch(uri:any[], body:any, urlParams:object=null, options:RequestOptions=new RequestOptions(), directAppend:string=''): Observable<CWHttpResponse> {
		this.apiTokenProtect();
		if (this.useApiVersion) {
			uri = [this.apiVersion, ...uri];
		}
		let path = _check_url(this.getFullPath({ uri, params: urlParams }));
		path = this.appendDirectAppend(path, directAppend);
		if (options == null) {
			options = new RequestOptions();
		}
		options.headers = this.headers;
		return this.http.patch(path, body, options)
			.pipe(
				map(response => {
					if (this.customResponseHandler) {
						let handler = this.customResponseHandler;
						this.customResponseHandler = null;
						return handler(response);
					}
					this.customErrorHandler = null;
					return this.parseResponse(response);
				}),
				catchError(error => {
					let handler = this.customErrorHandler ? this.customErrorHandler(error) : this.handleError(error);
					this.customErrorHandler = null;
					return handler;
				})
			);
	}

	/**
	 * Wrapper for Angular's http.delete().
	 * Attaches headers, builds our api-url, and other conveniences that all of our requests need.
	 * @param uri       will be used to build the end point path.
	 * @param urlParams will be turned into "?key=value&key=value" at the end of the uri.
	 */
	protected _delete(uri: any[], urlParams: object = null, options:RequestOptions=new RequestOptions()): Observable<CWHttpResponse> {
		this.apiTokenProtect();
		if (this.useApiVersion) {
			uri = [this.apiVersion, ...uri];
		}
		let path = _check_url(this.getFullPath({ uri, params: urlParams }));
		if (options == null) {
			options = new RequestOptions();
		}
		options.headers = this.headers;
		return this.http.delete(path, options)
			.pipe(
				map(response => {
					if (this.customResponseHandler) {
						let handler = this.customResponseHandler;
						this.customResponseHandler = null;
						return handler(response);
					}
					this.customErrorHandler = null;
					return this.parseResponse(response);
					}),
				catchError(error => {
					let handler = this.customErrorHandler ? this.customErrorHandler(error) : this.handleError(error);
					this.customErrorHandler = null;
					return handler;
				})
			);
	}

	private parseResponse(response) {
		if (response._body) {
			response.body = JSON.parse(response._body);
		}
		response.success = false;
		if (response.status) {
			let status = response.status;
			response.success = status >= 200 && status < 300;
		}
		return response;
	}

	/**
	 * Builds the full URL:
	 * (path from Config) + parts.uri.join("/") + "?" + (key values in parts.params)
	 */
	protected getFullPath(parts: PathParts): string {
		let path: string = API_ROOT + '/';
		if (parts.uri) {
			path += parts.uri.map(p => p.toString()).join("/") + '/';
		}
		if (parts.params) {
			path += '?' + this.objectToParams(parts.params);
		}
		return path;
	}

	protected appendDirectAppend(path:string, directAppend:string): string {
		if (directAppend) {
			// Append either a ? or & to our path then append the directAppend.
			// Don't put a ? in the directAppend when calling this function ^.
			path += path.indexOf("?") == -1 ? '?' : '&';
			path += directAppend;
		}
		return path;
	}

	/**
	 * Converts an object to key value pairs in a string.
	 * For example: obj = {name: "Mario", job: "plumber", partner: "Luigi"} =>
	 * "name=Mario&job=plumber&partner=Luigi"
	 */
	protected objectToParams(obj: object): string {
		// to make typescript not complain
		let flat_pairs = Object.entries(obj).reduce(
			(arr, [key, value]) => {
				if ( (<any[]>(value||0)).length && typeof value !== typeof '' ) {
					// flatten array types (not strings)
					return arr.concat(
						[].map.call(<any[]>(value), inner_value => `${key}=${inner_value}`)
					);
				}
				else {
					// flatten scalars
					if ( value && typeof value === typeof {} ) {
						// "[object Object]" is an error
						console.warn("Cannot serialize object type", value);
						throw new Error("Failed to serialize all querystring arguments");
					}
					if ( typeof value === typeof void 0 ) {
						// ignore undefined values
						return arr;
					}

					return arr.concat(`${key}=${value}`);
				}
			},
			[]
		);

		let querystring = flat_pairs.join('&');

		return querystring;
	}

	private handleError(error: Response | any): Observable<any> {
		// TODO Replace with a remote logging infrastructure
		let errMsg: string;
		if (error instanceof Response) {
			const body: any = error.json() || '';
			const err = body.error || JSON.stringify(body);
			errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
		} else {
			errMsg = error.message ? error.message : error.toString();
		}

		console.log(errMsg);
		// the _body always comes back as json, like "{"detail":"Not found."}"
		// convert it so we don't have to every time.
		error._body = JSON.parse(error._body);
		error.custom_message = errMsg;
		throw error;
	}

	get headers() {
		let h = new Headers();

		let auth_token = this.localStorageService.getApiToken();
		if (auth_token) {
			h.append('Authorization', 'Token ' + auth_token);
		}

		return h;
	}
}
