import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
	private localStorageKeys = {
		apiToken: 'api_token'
	};

	constructor(){}

	public apiTokenIsSet(): boolean {
		return this.getApiToken() != null;
	}

	public getApiToken(): string {
		return this.getItem(this.localStorageKeys.apiToken);
	}

	public setApiToken(apiKey): void {
		this.setItem(this.localStorageKeys.apiToken, apiKey);
	}

	public clearApiToken(): void {
		this.removeItem(this.localStorageKeys.apiToken);
	}

	// abstracted so we can do other things if we want to, like logging
	public setItem(key:string, value:string): void {
		localStorage.setItem(key, value);
	}

	// abstracted so we can do other things if we want to, like logging
	public getItem(key:string): string {
		return localStorage.getItem(key);
	}

	private removeItem(key:string): void {
		localStorage.removeItem(key);
	}
}
