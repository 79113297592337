import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	providers: [],
	selector: 'page-not-found',
	styleUrls: ['page-not-found.css'],
	templateUrl: 'page-not-found.html',
})
export class PageNotFoundComponent implements OnInit{

	constructor(
		private router: Router,
	) {

	}

	public ngOnInit() : void {

	}

	public getUrl(): string {
		return this.router.url;
	}
}
