export class User{
	first_name		: string;
	last_name		: string;
	initials		: string;
	id				: number;
	username		: string;
	groups			: {[name:string]:boolean} = {};

	public constructor(){}
}
